import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'tzg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  
  HttpActivity: boolean;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];

  constructor(private location: Location, private metaService: Meta, private router: Router, private titleService: Title) { }

  ngOnInit() {
    
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });

    this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationStart) {
        if (ev.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (ev instanceof NavigationEnd) {
        this.setMetaTags(ev.url);
        if (ev.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else {
          window.scrollTo(0, 0);
        }
      }
    });
  }

  private setMetaTags(url: string): void {
    //this.metaService.updateTag({ name: 'last-modified', content: '2020-02-01' }), 'name=last-modified'; //TODO:
    //this.titleService.setTitle('a new title!');
  }
  
}
