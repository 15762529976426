import { Injectable } from '@angular/core';

@Injectable()
export class LoggingService {

  private ConsoleEnabled: boolean;

  constructor() {
    this.ConsoleEnabled = true;
    this.warn('LoggingService initialized.');
  }

  public setConsoleStatus(environment: string): void {
    this.ConsoleEnabled = environment !== 'Production';
    if(!this.ConsoleEnabled)
      console.warn('CONSOLE DISABLED');
  }

  public error(s: any): void {
    if (this.ConsoleEnabled)
      console.error(s);
  }
  public info(s: any): void {
    if (this.ConsoleEnabled)
      console.info(s);
  }
  public log(s: any): void {
    if (this.ConsoleEnabled)
      console.log(s);
  }
  public warn(s: any): void {
    if (this.ConsoleEnabled)
      console.warn(s);
  }

}
