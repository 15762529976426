
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common';//TODO:
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material.module'; //TODO:
import { AppComponent } from './app.component';

import { SignInComponent } from './sign-in/sign-in.component';

import {
  AjaxService,
  LoggingService,
  UtilityService
} from './providers';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent
  ],
  entryComponents: [
    // dialog components
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    MaterialModule
  ],
  providers: [
    // services
    AjaxService,
    LoggingService,
    UtilityService,
    // interceptors
    // pipes
    DatePipe //TODO:
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
