import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SignInComponent } from './sign-in/sign-in.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('src/app/components/areas/home/home.module').then(m => m.HomeModule) },
  { path: 'signin', component: SignInComponent },
  { path: '**', loadChildren: () => import('src/app/components/areas/not-found404/not-found404.module').then(m => m.NotFound404Module) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
