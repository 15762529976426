import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { LoggingService } from './logging.service';
import { UtilityService } from './utility.service';

@Injectable()
export class AjaxService {

  constructor(private http: HttpClient, private loggingService: LoggingService, private utilityService: UtilityService) {
    this.loggingService.warn('AjaxService initialized.');
  }

  public DELETE<T>(url: string): Observable<T> {

    this.loggingService.info('AjaxService.DELETE');
    this.loggingService.info(`Endpoint: ${url}`);

    return this.http
      .delete(
        this.utilityService.ApiEndpointRoot + url,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'X-API-Version': '1.0'
          })
        }
      ) as Observable<T>;
  }

  public GET<T>(url: string): Observable<T> {

    this.loggingService.info('AjaxService.GET');
    this.loggingService.info(`Endpoint: ${url}`);

    return this.http
      .get(
        this.utilityService.ApiEndpointRoot + url,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'X-API-Version': '1.0'
          })
        }
      ) as Observable<T>;
  }

  public POST<T>(url: string, payload: any): Observable<T> {

    this.loggingService.info('AjaxService.POST');
    this.loggingService.info(`Endpoint:\n${url}\nPayload:`);
    this.loggingService.info(payload);

    return this.http
      .post(
        this.utilityService.ApiEndpointRoot + url,
        payload,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'X-API-Version': '1.0'
          })
        }
      ) as Observable<T>;
  }

  public POSTwithProgress<T>(url: string, payload: FormData): Observable<HttpEvent<T>> {

    this.loggingService.info('AjaxService.POSTwithProgress');
    this.loggingService.info(`Endpoint:\n${url}\nPayload:`);
    this.loggingService.info(payload);

    return this.http
      .post(
        this.utilityService.ApiEndpointRoot + url,
        payload,
        {
          headers: new HttpHeaders({
            'X-API-Version': '1.0'
          }),
          observe: 'events',
          reportProgress: true
        }
      ) as Observable<HttpEvent<T>>;
  }

}
