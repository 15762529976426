import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

import { LocalRoutes } from '../constants';
import { LoggingService } from './logging.service';

@Injectable()
export class UtilityService {

  public readonly ApiEndpointRoot: string;
  public readonly ApplicationEnvironment: string;
  private readonly ApplicationHost: string;
  private BreadcrumbSubject: BehaviorSubject<string>;
  public Breadcrumb: Observable<string>;

  constructor(private loggingService: LoggingService, private router: Router) {
    this.loggingService.setConsoleStatus(this.ApplicationEnvironment);
    this.loggingService.warn('UtilityService initialized.');

    this.ApplicationHost = window.location.host;

    switch (this.ApplicationHost) {
      case 'thezinniagroup.com':
      case 'www.thezinniagroup.com':
        this.ApiEndpointRoot = 'http://api.thezinniagroup.com/';
        this.ApplicationEnvironment = "Production";
        break;
        case 'www-staging.thezinniagroup.com':
          this.ApiEndpointRoot = 'http://api-staging.thezinniagroup.com/';
          this.ApplicationEnvironment = "Staging";
        break;
      default:
        this.ApiEndpointRoot = 'http://localhost:5000/';
        this.ApplicationEnvironment = "Development";
        break;
    }

    this.BreadcrumbSubject = new BehaviorSubject<string>('Sign In');
    this.Breadcrumb = this.BreadcrumbSubject.asObservable();

    this.loggingService.info(`Application started.\n\tApplicationHost detected as '${this.ApplicationHost}'\n\tApplicationEnvironment set to '${this.ApplicationEnvironment}'\n\tApiEndpointRoot set to '${this.ApiEndpointRoot}'`);
  }

  // public RandomInt(min: number, max: number): number {
  //   return Math.floor(Math.random() * (max - min + 1)) + min;
  // }

  public redirectToLanding(): void {
    this.router.navigate([LocalRoutes.Landing]);
  }

  public redirectToRoute(route: string) {
    this.router.navigate([route]);
  }

  public redirectToSignIn(): void {
    this.router.navigate([LocalRoutes.SignIn]);
  }

  // public setBreadcrumb(value: string): void {
  //   this.BreadcrumbSubject.next(value);
  // }

  // public get SysApplicationHost(): string {
  //   return this.ApplicationHost;
  // }

  // public get SysApplicationEnvironment(): string {
  //   return this.ApplicationEnvironment;
  // }

  // public get SysApiEndpointRoot(): string {
  //   return this.ApiEndpointRoot;
  // }

}
